import React, { useEffect, useState} from 'react';
import { CSVLink } from "react-csv";
import { Spin, Alert as AntAlert, Empty } from 'antd';
import styled from '@emotion/styled';
import { Button } from 'Components';
import moment from 'moment';
import {EXISTENCIAS_SA, EXISTENCIAS_SADM} from 'Queries'
import { useQuery } from '@apollo/client';
import { StockFilter } from './StockFilter';
import { StockTable } from './StockTable';

export const Stock = ({distribuidores}) => {
  const [distribuidor, setDistribuidor] = useState();
  const [fecha, setFecha] = useState();
  const [tabla, setTabla] = useState();
  const [ipadResolution, setIpadResolution] = useState();
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  
  const tablas = [
    { value: 'EXISTENCIA_NEXT_sa', label: 'Datos procesados y categorizados por fecha' },
    { value: 'EXISTENCIA_NEXT_sadm', label: 'Datos completos procesados sin fecha' },
  ];

  const header_EXISTENCIA_NEXT_sa = [
    { label: 'Código distribuidor', key: 'codigoDistribuidor' },
    { label: 'Código artículo-proveedor', key: 'codigoArticuloProveedor' },
    { label: 'Fecha', key: 'fecha' }, 
    { label: 'Unidades', key: 'unidades'},
  ]

  const header_EXISTENCIA_NEXT_sadm = [
    { label: 'Código distribuidor', key: 'codigoDistribuidor' },
    { label: 'Código artículo-proveedor', key: 'codigoArticuloProveedor' },
  ]
  
  const header = tabla === 'EXISTENCIA_NEXT_sa' ? header_EXISTENCIA_NEXT_sa : header_EXISTENCIA_NEXT_sadm;
  
  const columns = header.map((column) => ({
    Header: column.label,
    accessor: column.key,
  }));

  const { data: dataSA, error: errorSA, loading: loadingSA } = useQuery(
    EXISTENCIAS_SA,
    { 
      variables: { codDistribuidor: distribuidor, fecha: moment(fecha).format('YYYY-MM-DD')},
      skip: !distribuidor || !fecha || tabla !== 'EXISTENCIA_NEXT_sa',
    }
  );
  
  const { data: dataSADM, error: errorSADM, loading: loadingSADM } = useQuery(
    EXISTENCIAS_SADM,
    {
      variables: { codDistribuidor: distribuidor },
      skip: !distribuidor || tabla !== 'EXISTENCIA_NEXT_sadm', 
    }
  );

  const clearFilter = () => {
    setDistribuidor()
    setFecha()
    setTabla()
    setDatosFiltrados([]);
  }

  const updateResolution = () => {
    setIpadResolution(document.querySelector('#contentWidth').offsetWidth < 965)
  }

  const handleFiltroChange = () => {
    if (tabla === 'EXISTENCIA_NEXT_sa' && dataSA && dataSA.ExistenciaSA) {
      const datosFiltrados = dataSA.ExistenciaSA.filter (dato => {
        if (distribuidor && dato.codigoDistribuidor !== distribuidor)
          return false;
        if (fecha && moment(dato.fecha).format('YYYY-MM-DD') !== moment(fecha).format('YYYY-MM-DD'))
          return false;
        return true;
      });
      setDatosFiltrados(datosFiltrados);
    } else if (tabla === 'EXISTENCIA_NEXT_sadm' && dataSADM && dataSADM.ExistenciaSADM) {
      const datosFiltrados = dataSADM.ExistenciaSADM.filter(dato => {
        if (distribuidor && dato.codigoDistribuidor !== distribuidor) 
          return false;
        return true;
      });
      setDatosFiltrados(datosFiltrados);
    }
  }

  useEffect(() => {
    if (distribuidor && fecha && tabla || distribuidor && tabla && tabla === 'EXISTENCIA_NEXT_sadm') {
      handleFiltroChange();
    } else {
      setDatosFiltrados([]);
    }
    setTimeout(() => {
      updateResolution()
    }, 100)
    window.addEventListener('resize', updateResolution)
    return () => {
      window.removeEventListener('resize', updateResolution)
    }
  }, [distribuidor, fecha, tabla, dataSA, dataSADM])



  return (
    <div>
      <div id="contentWidth" style={{ 
        minWidth: '830px' 
      }}>
        <FilterWrapper>
          <StockFilter
            distribuidores={distribuidores}
            tabla={tabla}
            setTabla={setTabla}
            distribuidor={distribuidor}
            setDistribuidor={setDistribuidor}
            fecha={fecha}
            setFecha={setFecha}
            ipadResolution={ipadResolution}
          >
          </StockFilter>
          
          <Button
            disabled={!distribuidor && !tabla && !fecha}
            type="link"
            size="large"
            onClick={clearFilter}
            style={{
              marginLeft: 2,
              marginRight: 2,
              padding: '0 10px',
              fontSize: 12,
            }}
          >
            Resetear filtros
          </Button>
          <CSVLink
            headers={header}
            data={datosFiltrados.map(dato => ({
              ...dato,
              fecha: fecha ? moment(fecha).format('DD/MM/YYYY') : dato.fecha ? dato.fecha : '',
            }))}
            filename={`${distribuidor} - ${tabla}${tabla !== 'EXISTENCIA_NEXT_sadm' ? ` - ${moment(fecha).format('DD/MM/YYYY')}` : ''}.csv`}
            className="btn btn-primary"
            target="_blank"
            separator={";"}
          >
            <Button 
              disabled={ !distribuidor || !tabla || (tabla !== 'EXISTENCIA_NEXT_sadm' && !fecha) || loadingSA || loadingSADM } 
              type="primary" 
              style={{ 
                marginRight: 10,
                padding: '0 10px',
                fontSize: 12 
              }} 
            >
              Descargar CSV
            </Button>
          </CSVLink>
        </FilterWrapper>
      </div>
      {
        loadingSA || loadingSADM ? (
          <div style={{ 
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center', 
            padding: '20px',
            fontSize: '24px',
            marginTop: 10,
          }}>
            <Spin size="large"/>
            <div style={{ 
              fontSize:'24px',
              marginTop: 10,
            }}>
              Cargando datos...
            </div>
          </div>
        ) : !distribuidor || !tabla || (tabla !== 'EXISTENCIA_NEXT_sadm' && !fecha) ? (
          <Warning
              message="&nbsp; Seleccione los filtros"
              type="warning"
              showIcon
              style={{ 
                marginTop: 20,
                width: "300px",
              }}
            />
        ) : datosFiltrados.length === 0? (
          <div style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Empty 
              description={
                  <span style={{
                    fontSize: 24,
                  }}>
                    No hay datos que mostrar
                  </span> 
              } 
              imageStyle={{
                width: 300,
                height: 200,
              }}
            />
          </div>
        )
        : (
          <StockTable columns={columns} data={datosFiltrados} />
        )
      }
    </div>
  );
};

const FilterWrapper = styled.div`
  // position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  right: 0px;
  top: 65px;
  > div {
    margin: 0 2px
  }
`

const Warning = styled(AntAlert)`
  font-size: 24px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 10px auto;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

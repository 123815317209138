import React, { useContext, useState } from 'react'

import {
  AddDealerForm,
  Content,
  PageTitle,
  ResourceWrapper,
  NoDataForList,
  Spinner,
  Dealer,
  Stock
} from 'Components'
import { Header } from 'Fragments'
import { ALL_DISTRIBUTORS, CREATE_DISTRIBUTORS, EDIT_DISTRIBUTORS } from 'Queries'
import { GlobalContext, reverseSort } from 'Utils'

import { Layout, Row, Tabs as AntTabs,Button as AntButton } from 'antd'
import styled from '@emotion/styled'
import { isEmpty } from 'lodash'
import { useQuery, useMutation, useApolloClient } from '@apollo/client'

import { CSVLink } from "react-csv";
import  moment  from 'moment';
import TabPane from 'antd/lib/tabs/TabPane'

export const DealerManagement = () => {
  const context = useContext(GlobalContext)

  const [dealers, setDealers] = useState([])
  const [isRefetch, setIsRefetch] = useState(false)
  const [modalProps, setModalProps] = useState({
    visible: false,
    title: 'Añadir distribuidor',
    okText: 'Añadir',
  })

  const onCompleted = ({ Distributor }) => {
    const list = Distributor.length > 1 ? reverseSort(Distributor, 'modifEn') : Distributor;
    setDealers(list);
    setIsRefetch(false);
    context.resetKeepAliveTimer();
  }

  const resource = useQuery(ALL_DISTRIBUTORS, {
    fetchPolicy: 'network-only',
    onCompleted
  })

  const [createDealer, { loading: createLoading }] = useMutation(CREATE_DISTRIBUTORS, { onCompleted })
  const [editDealer, { loading: editLoading }] = useMutation(EDIT_DISTRIBUTORS, { onCompleted })

  const crearDistribuidores = (dealers) => {
    return dealers.map((dealer) => ({
      value: dealer.codigoDistribuidor,
      label: `${dealer.razonSocial} - ${dealer.codigoDistribuidor}`
    }));
  };

  const distribuidores = crearDistribuidores(dealers).sort((a, b) => a.label.localeCompare(b.label));

  const client = useApolloClient()
  client.addResolvers()

  const isLoading = isRefetch || editLoading || createLoading

  return (
    <Layout>
      <Header onlyLogo />
      <Content nosider={1}>
        <StyledTabs defaultActiveKey='1'>
          <TabPane tab="DISTRIBUIDORES" key='1'>
            {isLoading ? <Spinner /> : (
              <ResourceWrapper resource={resource}>  
                <Row justify="space-between" type="flex" style={{ padding: '15px 70px 0 70px' }}>
                  <PageTitle>distribuidores</PageTitle>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CSVLink
                      data={dealers.map(dealer => ({
                        ...dealer, 
                        modifEn: dealer.modifEn? moment(dealer.modifEn).format('DD/MM/YYYY HH:mm:ss') : '',
                        last_login: (dealer.last_login && dealer.last_login !== -62135596800000)? moment(dealer.last_login).format('DD/MM/YYYY HH:mm:ss') : '',
                        last_transmission_clientes: (dealer.last_transmission_clientes && dealer.last_transmission_clientes !== -62135596800000)? moment(dealer.last_transmission_clientes).format('DD/MM/YYYY HH:mm:ss') : '',
                        last_transmission_articulos: (dealer.last_transmission_articulos && dealer.last_transmission_articulos !== -62135596800000)? moment(dealer.last_transmission_articulos).format('DD/MM/YYYY HH:mm:ss') : '',
                        last_transmission_ventas: (dealer.last_transmission_ventas && dealer.last_transmission_ventas !== -62135596800000)? moment(dealer.last_transmission_ventas).format('DD/MM/YYYY HH:mm:ss') : '',
                        last_transmission_existencias: (dealer.last_transmission_existencias && dealer.last_transmission_existencias !== -62135596800000)? moment(dealer.last_transmission_existencias).format('DD/MM/YYYY HH:mm:ss') : '',

                        record_type_counts_clientes: (dealer.record_type_counts_clientes && dealer.record_type_counts_clientes >= 0)? dealer.record_type_counts_clientes : 'ERROR',
                        record_type_counts_articulos: (dealer.record_type_counts_articulos && dealer.record_type_counts_articulos >= 0)? dealer.record_type_counts_articulos : 'ERROR',
                        record_type_counts_existencias: (dealer.record_type_counts_existencias && dealer.record_type_counts_existencias >= 0)? dealer.record_type_counts_existencias : 'ERROR',
                        record_type_counts_ventas: (dealer.record_type_counts_ventas && dealer.record_type_counts_ventas >= 0)? dealer.record_type_counts_ventas : 'ERROR',
                      }))}
                      headers={[
                        { label: 'Distribuidor', key: 'codigoDistribuidor' },
                        { label: 'Razón social', key: 'razonSocial' },
                        { label: 'Fecha creación/modificación', key: 'modifEn' },
                        { label: 'CIF', key: 'cif' },
                        { label: 'Población', key: 'poblacion' },
                        { label: 'Provincia', key: 'provincia' },
                        { label: 'Dirección', key: 'direccionFiscal' },
                        { label: 'C.P.', key: 'codigoPostal' },
                        { label: 'Gerente', key: 'gerente' },
                        { label: 'Email gerente', key: 'email' },
                        { label: 'ERP', key: 'erp' },
                        { label: 'Proveedor HESA', key: 'codigoProveedorHesa' },
                        { label: 'Fecha último login usuarios', key: 'last_login' },
                        { label: 'Fecha última transmisión CLIENTES', key: 'last_transmission_clientes' },
                        { label: '#Registros CLIENTES', key: 'record_type_counts_clientes'},
                        { label: 'Fecha última transmisión ARTICULOS', key: 'last_transmission_articulos' },
                        { label: '#Registros ARTICULOS', key: 'record_type_counts_articulos' },
                        { label: 'Fecha última transmisión VENTAS', key: 'last_transmission_ventas' },
                        { label: '#Registros VENTAS', key: 'record_type_counts_ventas' },
                        { label: 'Fecha última transmisión EXISTENCIAS', key: 'last_transmission_existencias' },
                        { label: '#Registros EXISTENCIAS', key: 'record_type_counts_existencias' },
                        { label: 'Observaciones', key: 'observaciones' },
                        // Agrega más columnas según sea necesario
                      ]}
                      filename="Distribuidores.csv"
                      className="btn btn-primary"
                      target="_blank"
                      separator=";"
                    >
                      <Button
                        disabled={dealers.length === 0}
                        size="large"
                        onMouseLeave={() => {
                          document.activeElement.blur();
                        }}                 
                      >
                        Descargar CSV
                      </Button>
                    </CSVLink>
                  <Button
                    size="large"
                    onClick={() => setModalProps({
                      title: 'Añadir distribuidor',
                      okText: 'Añadir',
                      visible: true,
                      submit: createDealer
                    })}
                  >
                    Nuevo distribuidor
                  </Button>
                </div>
                </Row>
                <ListDiv>
                  {dealers.map((dealer, index) => (
                    <Dealer
                      key={'1' + index}
                      dealer={dealer}
                      refetchObjectives={resource.refetch}
                      setIsRefetch={setIsRefetch}
                      props={modalProps}
                      setProps={setModalProps}
                      editDealer={editDealer}
                    />
                  ))}
                  {isEmpty(dealers) && (
                    <NoDataForList text="No hay distribuidores que estén activos"></NoDataForList>
                  )}
                  <div style={{ height: '40px' }}></div>
                </ListDiv>
              </ResourceWrapper>
            )}
          </TabPane>
          <TabPane tab="STOCKS" key="2">
          {isLoading ? <Spinner /> : (
              <ResourceWrapper resource={resource}>  
                <Stock distribuidores={distribuidores.filter((item, index, self) => self.findIndex(t => t.value === item.value) === index)}/>
            </ResourceWrapper>
          )}
          </TabPane>
        </StyledTabs>
      </Content>
      <AddDealerForm props={modalProps} setProps={setModalProps} isLoading={false} />
    </Layout>)
}

const Button = styled(AntButton)`
  background: #008200;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  margin-top: 10px;
  &:hover: {
    background: #008200;
    color: white;
  }
`

const ListDiv = styled.div`
  height: calc(100vh - 170px);
  width: 100%;
  overflow-y: auto;
  padding: 0 70px;
`

const StyledTabs = styled(AntTabs)`
  .ant-tabs-nav .ant-tabs-nav-wrap {
    margin-left: 15px;
  }
  .ant-tabs-tab {
    padding: 10px 15px;
    font-size: 15px;
  }
  .ant-tabs-nav {
    margin: 0;
  }
`
import React from 'react';
import { useTable } from 'react-table';

export const StockTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <div style={{ 
      marginTop: '10px', 
      maxHeight: '80vh', 
      overflowY: 'auto'
    }}>      
      <table {...getTableProps()} style={{
        backgroundColor: '#f9f9f9',
        padding: '10px',
        margin: '20px auto 0 auto',
      }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} key={column.id} style={{
                  padding: '10px',
                  borderBottom: '1px solid darkgray',
                  borderLeft: '1px solid #eee',
                  borderRight: '1px solid #eee',
                  color: '#205527',
                  textAlign: 'center',
                }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{
          padding: '10px',
          overflowY: 'auto',
        }}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id} style={{
                padding: '10px',
              }}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} key={cell.column.id} style={{
                    padding: '10px',
                    borderBottom: '1px solid darkgray',
                    borderLeft: '1px solid #eee',
                    borderRight: '1px solid #eee',
                    textAlign: 'center',
                  }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};


import gql from "graphql-tag";

export const EXISTENCIAS_SA_FRAGMENT = gql`
  fragment existenciasSaFragment on ExistenciaSA {
    codigoDistribuidor
    codigoArticuloProveedor
    fecha
    unidades
  }
`;

export const EXISTENCIAS_SA = gql`
  query existenciasSA ($codDistribuidor: Long, $fecha: String) {
    ExistenciaSA: existenciasSA(codDistribuidor: $codDistribuidor, fecha: $fecha) {
      ...existenciasSaFragment
    }
  }
  ${EXISTENCIAS_SA_FRAGMENT}
`;

export const EXISTENCIAS_SADM_FRAGMENT = gql`
  fragment existenciasSadmFragment on ExistenciaSADM {
    codigoDistribuidor
    codigoArticuloProveedor
  }
`;

export const EXISTENCIAS_SADM = gql`
  query existenciasSADM ($codDistribuidor: Long) {
    ExistenciaSADM: existenciasSADM(codDistribuidor: $codDistribuidor) {
      ...existenciasSadmFragment
    }
  }
  ${EXISTENCIAS_SADM_FRAGMENT}
`;


import React from 'react';
import { Select, DatePicker } from 'antd';
import  moment  from 'moment';



export const StockFilter = ({ 
  distribuidores, 
  tabla, 
  setTabla, 
  distribuidor, 
  setDistribuidor, 
  fecha, 
  setFecha, 
  ipadResolution 
}) => {
  const handleDistribuidorChange = (value) => {
    setDistribuidor(value);
  };

  const handleFechaChange = (date) => {
    setFecha(date);
  };

  const handleTablaChange = (value) => {
    setTabla(value);
  };

  return (
    <div>
      <Select
        value={tabla}
        mode="default"
        placeholder="Seleccionar datos"
        options={[
          { value: 'EXISTENCIA_NEXT_sa', label: 'Datos procesados y categorizados por fecha' },
          { value: 'EXISTENCIA_NEXT_sadm', label: 'Datos completos procesados sin fecha' },
        ]}
        onChange={handleTablaChange}
        style={
          ipadResolution
            ? { width: 180, minWidth: 120 }
            : { width: 300, minWidth: 120 }
        }
      />
      <Select
        value={distribuidor}
        placeholder="Seleccionar distribuidor"
        options={distribuidores}
        onChange={handleDistribuidorChange}
        style={
          ipadResolution
            ? { width: 200, minWidth: 120 }
            : { width: 300, minWidth: 120 }
        }
      >
        {distribuidores.map((distribuidor) => (
          <Select.Option key={distribuidor.value} value={distribuidor.value}>
            {distribuidor.label}
          </Select.Option>
        ))}
      </Select>
      {tabla !== 'EXISTENCIA_NEXT_sadm' && (
        <DatePicker
          value={fecha}
          onChange={handleFechaChange}
          disabledDate={(current) => current > moment()}
          style={
            ipadResolution
              ? { width: 150, minWidth: 120 }
              : { width: 220, minWidth: 120 }
          }
        />
      )}
    </div>
  );
};
